<template>
    <div id="warpper">
        <!-- <div class="demo"></div> -->
        <ejs-documenteditorcontainer ref="documenteditor" :toolbarItems="items" :toolbarClick="onToolbarClick" :serviceUrl="serviceUrl" height="100vh" id="container" :enableToolbar="true" :enableSfdtExport="true" :enablePrint="false"></ejs-documenteditorcontainer>
    </div>
</template>
<script>
import Vue from "vue";
// import {
//   DocumentEditorContainerPlugin,
//   DocumentEditorContainerComponent,
//   Toolbar,
// } from "@syncfusion/ej2-vue-documenteditor";

// Vue.use(DocumentEditorContainerPlugin);

export default {
    name: "docEditor",
    data() {
        return {
            // serviceUrl: "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/",
            items: [
                {
                    prefixIcon: "e-de-ctnr-lock",
                    tooltipText: "Save Document",
                    text: "Save doc",
                    id: "Custom",
                },
                "New",
                "Open",
                "Separator",
                "Undo",
                "Redo",
                "Separator",
                "Image",
                "Table",
                "Hyperlink",
                "Bookmark",
                "TableOfContents",
                "Separator",
                "Header",
                "Footer",
                "PageSetup",
                "PageNumber",
                "Break",
                "InsertFootnote",
                "InsertEndnote",
                "Separator",
                "Find",
                "Separator",
                "Comments",
                "TrackChanges",
                "LocalClipboard",
                "RestrictEditing",
                "Separator",
                "FormFields",
                "UpdateFields",
            ],
        };
    },
    provide: {
        DocumentEditorContainer: [Toolbar],
    },
    methods: {
        onToolbarClick: function(args) {
            switch (args.item.id) {
                case "Custom":
                    //Disable image toolbar item.{}
                    {
                        this.$refs.documenteditor.ej2Instances.documentEditor.save("sample", "Docx");
                        // this.$refs.documenteditor.ej2Instances.toolbar.enableItems(4, false);
                    }

                    break;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
// @import "~@syncfusion/ej2-base/styles/material.css";
// @import "~@syncfusion/ej2-buttons/styles/material.css";
// @import "~@syncfusion/ej2-inputs/styles/material.css";
// @import "~@syncfusion/ej2-popups/styles/material.css";
// @import "~@syncfusion/ej2-lists/styles/material.css";
// @import "~@syncfusion/ej2-navigations/styles/material.css";
// @import "~@syncfusion/ej2-splitbuttons/styles/material.css";
// @import "~@syncfusion/ej2-dropdowns/styles/material.css";
// @import "~@syncfusion/ej2-vue-documenteditor/styles/material.css";

::v-deep .e-documenteditorcontainer {
    background: red;
    height: 100vh !important;
}

.e-documenteditorcontainer {
    height: 100vh;
}

#warpper {
    width: 100%;
    height: 100vh;
    background: red;
}
</style>
